$text-max-width: 66ch;

html, body {
  height: 100%;
  margin: 0;
}

body {
  overflow-y: hidden;
  text-align: left;
}

#main-content {
  width: 100%;
  overflow: auto;
}

#text-max-width {
  max-width: $text-max-width;
  margin-left: auto!important;
  margin-right: auto!important;
}

#main-content, .fullscreenPadding {
  @media only screen and (max-width: 399px) {
    padding: 1em;
  }
  @media only screen and (min-width: 400px) {
    padding: 1.5em;
  }
  @media only screen and (min-width: 500px) {
    padding: 2em;
  }
}
