span.mr-tooltip {
  cursor: pointer;
  
  + a {
    display: none;
    margin-top: 0.1rem !important;
    margin-bottom: 0.1rem !important;

    p {
      // overrule _typography.scss: #main-content article .mr-content-below > p
      margin-top: 0rem !important;
      margin-bottom: 0.3rem !important;
    }
  }
}

span.mr-show-tooltip + a {
  // box
  display: flex;
  z-index: 100;
  width: auto;
  justify-content: center;
  margin: 0 0 0 0;

  // border
  border: black 1px solid;
  border-radius: 5px;

  // text, color and font
  padding: 0.5vw 1vw 0vw 1vw;
  background-color: var(--primary-light-color);
  color: black !important;
  font-weight: normal;
  font-style: normal;

  // fade in
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
