// animations
@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// font-styling
.mr-bold {
  font-weight: bold;
}

.mr-underline {
  text-decoration: underline;
}

.mr-italic {
  font-style: italic;
}

// colors
.mr-primary-color {
  color: var(--primary-color) !important;
}

.mr-accent-color {
  color: var(--accent-color) !important;
}

.mr-medium-accent-color {
  color: var(--accent-medium-color);
}

.mr-warn-color {
  color: var(--warn-color) !important;
}

//  alignment
.mr-spacer {
  flex: 1 1 auto;
}

.mr-align-center {
  text-align: center;
}

.mr-align-right {
  text-align: right;
}

// pointer
.mr-pointer {
  cursor: pointer;
}
