// styling for norm-pages etc. generally
.mr-norms-display {
    table {
      width: 100%;
    }
  
    table > tr > td {
      vertical-align: middle;
      text-align: left;
    }
  
    table > tr > td:first-child {
      width: 3rem;
    }
  
    .mr-norm-spacing {
      letter-spacing: 0.1em;
    }
  
    .mr-norm-nowrap {
      white-space: nowrap;
    }
  
    .mr-norm-preserve {
    white-space: pre;
    }
  
    // .mr-norm-indent {
    //     margin-left: 6mm;
    // }
  
    // Footnotes
    // .mr-norm-footnote-sign {
    //   font-weight: normal;
    // }
  
    .mr-norm-hide-me {
      display: none;
    }
  
    // <Title>-formats
    h2.mr-norm-hstyle {
      text-align: center;
      font-size: 110%;
      letter-spacing: 0,2em;
    }
  
    h3.mr-norm-hstyle {
      text-align: center;
      font-size: 110%;
    }
  
    h4.mr-norm-hstyle {
      text-align: center;
      font-size: 100%;
      letter-spacing: 0,2em;
      font-weight: bold;
    }
  
    h5.mr-norm-hstyle {
      text-align: center;
      font-size: 100%;
      font-weight: bold;
    }
  
    h6.mr-norm-hstyle {
      text-align: center;
      font-size: 100%;
      letter-spacing: 0,2em;
    }
  
    h6.mr-norm-hstyle-sub1 {
      text-align: center;
      font-size: 100%;
    }
  
    h6.mr-norm-hstyle-sub2 {
      text-align: center;
      font-size: 100%;
    }
  
    // Table
    .mr-norm-tableBorder {
        border: 1px solid;
        border-collapse: collapse;
    }
  
    .mr-norm-tableFullWidth {
        width: 100%;
    }
  
    .mr-norm-tablebodyAlignTop {
        vertical-align: top;
    }
  
    .mr-norm-tableRowSep {
        border-bottom: 1px solid;
    }
  
    .mr-norm-tableColSep {
        border-right: 1px solid;
    }
  
    // DL/DD/DT
    dl {
      margin-top: 0.1em;
      margin-bottom: 0.1em;
    }
  
    dt {
        float: left;
        margin-left: 1em;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
    }
  
    dt.mr-norm-no-marginleft, div.mr-norm-footnote-paragraph > dl > dt {
        margin-left: 0em;
    }
  
    dd {
        margin-left: 3em;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
    }
  
    dd.mr-norm-marginleft, div.mr-norm-footnote-paragraph > dl > dd {
        margin-left: 2em;
    }
  }
  
  // Section-Display (used for section page and section-tooltip)
  .mr-section-display {
      .sectionHeaderArrows {
        float: left;
      }
      
      .sectionHeaderArrows > span {
        display: inline;
      }
      
      #section-header-text {
        vertical-align: middle;
        font-weight: bold;
      }
      
      #section-header-text sup.mr-norm-footnote-sign {
        vertical-align: top;
        margin-left: 0.2em;
      }
      
      tr td {
        vertical-align: top;
      }
      
      .mr-main-norm-content > div {
        padding-top: 0.5em;
      }
      
      .padding-top {
        padding-top: 2em;
      }
    }
    