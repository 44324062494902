@use '@angular/material' as mat;

// Own typography (very close to  Typograph --> font-size, line-height, font-weight, font-family, and letter-spacing
$ecourse-typography: mat.define-typography-config(
  $font-family: 'Roboto, Helvetica Neue,  Verdana, Arial, sans-serif',
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-6: mat.define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400),
);

// as of v.15 mat.legacy-core no longer includes default typography styles
// @include mat.all-component-typographies($ecourse-typography);        // Bsp.: IuphoriaButton ist dann  kleiner
@include mat.all-legacy-component-typographies($ecourse-typography);    // Bsp.: IuphoriaButton ist da noch größer

main article {
  .mr-content-below,
    
  // TODO: Das sieht sehr chaotisch aus --> nicht lieber gezielt ".mr-content-below" einsetzen?
  mr-presentation,
  mr-folding .mat-expansion-panel-body,
  mr-norm-display section,
  mr-norm-display article {
    > header > h1 {
      @include mat.typography-level($ecourse-typography, 'headline-4');
      margin-bottom: 3rem;
      text-align: center;
      word-wrap: break-word;

      // Media: --> hier einfach andere Klasse zuweisen? Oder typography übergreifend für mobile anpassen?
      @media only screen and (max-width: 499px) {
        font-size: 26px;
        margin-bottom: 1rem;
      }
    }

    .headline {
      @include mat.typography-level($ecourse-typography, 'headline-6');
      color: var(--primary-color);
      word-wrap: break-word;
      margin-top: 1.5rem;
      margin-bottom: 0rem;
      @media only screen and (max-width: 499px) {
        font-size: 18px;
      }
    }

    .title {
      @include mat.typography-level($ecourse-typography, 'subtitle-1');
      color: var(--primary-color);
      word-wrap: break-word;
      margin-top: 1.2rem;
      margin-bottom: 0rem;
    }

    .headline + .title {
      margin-top: 0.2rem;
    }

    > p {
      margin-top: 0.5rem;
      margin-bottom: 0rem;
      text-indent: 0.65rem;
    }

    .headline + p {
      margin-top: 0.3rem;
    }

    .title + p {
      margin-top: 0.3rem;
    }

    > p + p {
      margin-top: 0.8rem;
    }
  }
}
