// buttons (used e.g. for sidenav-buttons, previous/next chapter, related-chapters
.mr-accent-button {
  color: var(--accent-stronger-color) !important;
  background-color: var(--accent-light-color) !important;
  text-align: left !important;
}

.mr-active-accent-button {
  color: white !important;
  background-color: var(--accent-medium-color) !important;
  text-align: left !important;
}

main article {
  // styled box used e.g. for facts
  .mr-primary-background-box {
    border-radius: 10px;
    padding: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.5em;
    background-color: var(--primary-light-color);
  }

  // links
  a,
  a:visited {
    cursor: pointer;
    color: var(--accent-color);
    text-decoration: none;

    mat-icon.mr-external-link {
      // see also external-links-transform (setting inline="true" and target="_blank")
      text-indent: 0px;
      vertical-align: bottom;
    }
  }

  // keyword-link that is bold
  mr-keyword-reference > .mr-tooltip > .mr-bold {
    color: var(--black-text-color);
    text-decoration: underline;
    text-decoration-color: var(--accent-color);
    text-decoration-thickness: 1px;
  }

  // unordered lists --> see in unordered-lists-config.ts if it matches
  div.listLevel0,
  div.listLevel1,
  div.listLevel2,
  div.listLevel3,
  div.listLevel4,
  div.listLevel5 {
    > div {
      display: flex;
      margin-top: 1rem;

      > div:first-child > span {
        margin-right: 0.4em;
        display: inline-block;
        width: 12px;
      }
    }
    + p {
      margin-top: 0rem !important;
    }
  }

  div.listLevel0 > div {
    padding-left: 1rem;
  }
  div.listLevel1 > div {
    padding-left: 2rem;
  }
  div.listLevel2 > div {
    padding-left: 3rem;
  }
  div.listLevel3 > div {
    padding-left: 4rem;
  }
  div.listLevel4 > div {
    padding-left: 5rem;
  }
  div.listLevel5 > div {
    padding-left: 6rem;
  }

  .listLevel0 + .listLevel1,
  .listLevel1 + .listLevel2,
  .listLevel2 + .listLevel3,
  .listLevel3 + .listLevel4,
  .listLevel4 + .listLevel5 {
    div {
      margin-top: 0.2rem !important;
    }
  }

  div[referenced] {
    // important to reduce tooltip-space around text in some cases (as margin-top is set/inherited for listLevel-Variations
    margin-top: 0px !important;
  }

  // Ordered lists
  ol {
    list-style-type: upper-alpha;
  }

  ol > li > ol {
    list-style-type: upper-roman;
  }

  ol > li > ol > li > ol {
    list-style-type: decimal;
  }

  ol > li > ol > li > ol > li > ol {
    list-style-type: lower-alpha;
  }
}
