@use '@angular/material' as mat;

// Palettes
$mr-primary-palette: mat.define-palette(mat.$light-green-palette, 800);
$mr-accent-palette: mat.define-palette(mat.$purple-palette);
$mr-warn-palette: mat.define-palette(mat.$red-palette);

// Colors
$primary-color: mat.get-color-from-palette($mr-primary-palette);
$primary-light-color: mat.get-color-from-palette($mr-primary-palette, 100);
$accent-color: mat.get-color-from-palette($mr-accent-palette);
$accent-light-color: mat.get-color-from-palette($mr-accent-palette, 50);
$accent-medium-color: mat.get-color-from-palette($mr-accent-palette, 400);
$accent-stronger-color: mat.get-color-from-palette($mr-accent-palette, 600);
$warn-color: mat.get-color-from-palette($mr-warn-palette);

// CSS-vars
:root {
  --primary-color: #{$primary-color};
  --primary-light-color: #{$primary-light-color};
  --accent-color: #{$accent-color};
  --accent-light-color: #{$accent-light-color};
  --accent-medium-color: #{$accent-medium-color};
  --accent-stronger-color: #{$accent-medium-color};
  --warn-color: #{$warn-color};
  --black-text-color: rgba(0, 0, 0, 0.87);
}
